import { makeAutoObservable, runInAction } from 'mobx';

import { Awaited } from '../interfaces';
import * as api from '../services/api';

export default class AuthStore {
  email?: string;
  isLoading: boolean;
  error: Error | null;

  constructor() {
    makeAutoObservable(this);
    this.isLoading = false;
    this.error = null;
  }

  private onError = (err: Error) => {
    this.error = err;
  };

  private handlePromise = <
    T extends (arg: any) => Promise<Awaited<ReturnType<T>>>
  >(
    fn: T,
    args?: Parameters<T>[0]
  ) => {
    this.isLoading = true;
    this.error = null;
    return fn({
      ...args,
    })
      .then((resp) => {
        runInAction(() => {
          this.isLoading = false;
        });
        return resp;
      })
      .catch((error) => {
        runInAction(() => {
          this.isLoading = false;
        });
        this.onError(error);
      });
  };

  signUp = (email: string, password: string) =>
    this.handlePromise(api.signUp, {
      email,
      password,
    }).then((resp) => {
      if (resp) {
        runInAction(() => {
          this.email = resp.email;
        });
      }
      return resp;
    });

  logIn = (email: string, password: string) =>
    this.handlePromise(api.logIn, {
      email,
      password,
    }).then((resp) => {
      if (resp) {
        runInAction(() => {
          this.email = resp.email;
        });
      }
      return resp;
    });

  logOut = () =>
    this.handlePromise(api.logOut).then(() => {
      runInAction(() => {
        this.email = undefined;
      });
    });
}
