import {
  useCallback,
  useEffect,
  useRef,
  ReactElement,
  useContext,
} from 'react';

import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import isEqual from 'react-fast-compare';

import { TableContext } from '../../contexts/TableContext';
import { ETableEvent, IPlayer } from '../../interfaces';
import { useSnackbars } from '../../services/useSnackbars';

function handleScrollToBottom() {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: 'smooth',
  });
}

const TableEventHandler = observer(
  ({ children }: { children: ReactElement }) => {
    const setSnackbars = useSnackbars();

    const tableStore = useContext(TableContext)!;
    const tableFromApi = tableStore.tableFromAPI!;
    const tableFromApiRef = useRef(tableFromApi);
    const bettingId = tableStore.bettingId;
    const bettingIdRef = useRef(tableStore.bettingId);

    const handleAddRemovePlayer = useCallback(
      (event: ETableEvent, oldPlayers: IPlayer[], newPlayers: IPlayer[]) => {
        const oldIds = oldPlayers.map((p) => p.id);
        const newIds = newPlayers.map((p) => p.id);
        const removedPlayerIds = difference(oldIds, newIds);
        const addedPlayerIds = difference(newIds, oldIds);
        const removedPlayer = oldPlayers.find(
          (p) => p.id === removedPlayerIds[0]
        );
        const addedPlayer = newPlayers.find((p) => p.id === addedPlayerIds[0]);

        switch (event) {
          case 'KICK':
            const adminName = tableStore.adminName;
            setSnackbars([
              {
                id: 'PLAYER_KICK',
                severity: 'warning',
                message: `${
                  removedPlayer?.name || 'Someone'
                } was kicked by ${adminName}`,
                icon: '🥾',
              },
            ]);
            break;
          case 'LEAVE':
            setSnackbars([
              {
                id: 'PLAYER_LEAVE',
                severity: 'success',
                message: `${removedPlayer?.name || 'Someone'} left`,
                icon: '🚪',
              },
            ]);
            break;
          case 'JOIN':
            setSnackbars([
              {
                id: 'PLAYER_JOIN',
                severity: 'success',
                message: `${addedPlayer?.name || 'Someone else'} joined`,
                icon: '👋',
              },
            ]);
            break;
          default:
            break;
        }
      },
      [setSnackbars, tableStore.adminName]
    );

    useEffect(
      function handleTableUpdate() {
        autorun(() => {
          if (
            tableFromApiRef.current &&
            !!tableFromApi &&
            !isEqual(tableFromApi, tableFromApiRef.current)
          ) {
            if (['KICK', 'LEAVE', 'JOIN'].includes(tableFromApi.last_event)) {
              handleAddRemovePlayer(
                tableFromApi.last_event,
                cloneDeep(tableFromApiRef.current!.game.players),
                tableFromApi.game.players
              );
            }

            if (
              tableFromApi.game.status === 'COMPLETE' &&
              tableFromApiRef.current.game.status !== 'COMPLETE' &&
              !!tableFromApi.game.victors.length
            ) {
              setSnackbars([
                {
                  id: 'VICTORS',
                  severity: 'success',
                  message: tableFromApi.game.victors
                    .map(({ name }) => `${name} wins!`)
                    .join(' '),
                  icon: '💰',
                },
              ]);
            }
          }
        });
        tableFromApiRef.current = tableFromApi;
      },
      [handleAddRemovePlayer, setSnackbars, tableFromApi]
    );

    useEffect(
      function handleBettingIdUpdate() {
        autorun(() => {
          if (
            bettingIdRef.current &&
            !!bettingId &&
            bettingId !== bettingIdRef.current
          ) {
            if (
              bettingIdRef.current !== bettingId &&
              bettingId === tableFromApi.game.me.id
            ) {
              handleScrollToBottom();
            }
          }
        });
        bettingIdRef.current = bettingId;
      },
      [bettingId, tableFromApi.game.me.id]
    );

    return children;
  }
);

export default TableEventHandler;
