import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { colors } from '../../constants/theme';

const useStyles = makeStyles((theme) => {
  return {
    header: {
      color: colors.primary,
    },
    list: {
      padding: 0,
    },
    listItem: {
      paddingTop: 3,
      paddingBottom: 0,
      paddingLeft: 4,
    },
    listTextItem: {
      marginTop: 0,
      marginBottom: 0,
      color: colors.grayDarkColor,
    },
    listTextItemError: {
      marginTop: 0,
      marginBottom: 0,
      color: colors.danger,
    },
  };
});

function PasswordRulesHelperText({
  password = '',
  shouldDisplayErrors = false,
  validationConfig,
}: {
  password?: string;
  shouldDisplayErrors?: boolean;
  validationConfig: { regex: RegExp; message: string }[];
}) {
  const classes = useStyles();

  return (
    <List classes={{ root: classes.list }}>
      <Typography variant="caption" className={classes.header}>
        Make it a good one:
      </Typography>
      {validationConfig.map(({ regex, message }) => (
        <ListItem key={message} classes={{ root: classes.listItem }}>
          <ListItemText
            classes={{
              root:
                !regex.test(password) && shouldDisplayErrors
                  ? classes.listTextItemError
                  : classes.listTextItem,
            }}
            primary={`• ${message}`}
            primaryTypographyProps={{
              variant: 'caption',
            }}
          />
        </ListItem>
      ))}
    </List>
  );
}

export default PasswordRulesHelperText;
