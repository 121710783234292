import { ITable } from '../interfaces';

import axios from './axios';
import destructureAxiosReq from './destructureAxiosReq';

export function addPlayerToTable({
  id,
  tableId,
}: {
  id: string;
  tableId: string;
}) {
  return destructureAxiosReq(
    axios.post(`/api/v1/table/${tableId}/sit`, {
      player_id: id,
    })
  );
}

export function bet({
  id,
  amount,
  tableId,
  roundId,
}: {
  amount: number;
  id: string;
  roundId: string;
  tableId: string;
}) {
  return destructureAxiosReq<void>(
    axios.post(`/api/v1/table/${tableId}/bet`, {
      amount,
      player_id: id,
      round_id: roundId,
    })
  );
}

export function createPlayer({
  name,
  tableId,
}: {
  name: string;
  tableId: string;
}) {
  return destructureAxiosReq<ITable>(
    axios.post(`/api/v1/table/${tableId}/player`, {
      name,
    })
  );
}

export function createTable({
  starting_stack,
  big_blind,
  small_blind,
}: {
  starting_stack: number;
  big_blind: number;
  small_blind: number;
}) {
  const payload = {
    starting_stack,
    big_blind,
    small_blind,
  };
  return destructureAxiosReq<ITable>(axios.post(`/api/v1/table`, payload));
}

export function deal({ tableId }: { tableId: string }) {
  return destructureAxiosReq<void>(axios.post(`/api/v1/table/${tableId}/deal`));
}

export function emote({
  tableId,
  id,
  message,
}: {
  tableId: string;
  id: string;
  message: string;
}) {
  return destructureAxiosReq<void>(
    axios.post(`/api/v1/table/${tableId}/emote`, {
      message,
      player_id: id,
    })
  );
}

export function fold({
  id,
  roundId,
  tableId,
}: {
  id: string;
  roundId: string;
  tableId: string;
}) {
  return destructureAxiosReq<void>(
    axios.post(`/api/v1/table/${tableId}/fold`, {
      player_id: id,
      round_id: roundId,
    })
  );
}

export function logIn({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  return destructureAxiosReq<{ email: string; details: '' }>(
    axios.post(`/api/v1/account/login`, {
      email,
      password,
    })
  );
}

export function logOut() {
  return destructureAxiosReq<void>(axios.post(`/api/v1/account/logout`));
}

export function playerToSpectator({
  id,
  tableId,
}: {
  id: string;
  tableId: string;
}) {
  return destructureAxiosReq<void>(
    axios.post(`/api/v1/table/${tableId}/stand`, {
      player_id: id,
    })
  );
}

export function removePlayer({
  id,
  tableId,
  kicked,
}: {
  id: string;
  tableId: string;
  kicked: boolean;
}) {
  return destructureAxiosReq<void>(
    axios.delete(`/api/v1/table/${tableId}/player`, {
      data: {
        player_id: id,
        kicked,
      },
    })
  );
}

export function revealCards({
  id,
  tableId,
  cardIndices,
  handId,
}: {
  id: string;
  tableId: string;
  cardIndices: number[];
  handId: string;
}) {
  return destructureAxiosReq<void>(
    axios.post(`/api/v1/table/${tableId}/reveal`, {
      player_id: id,
      card_indexes: cardIndices,
      hand_id: handId,
    })
  );
}

export function signUp({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  return destructureAxiosReq<{ email: string; details: '' }>(
    axios.post(`/api/v1/account/signup`, {
      email,
      password,
    })
  );
}

export function updateGameSettings({
  smallBlind,
  bigBlind,
  startingStack,
  tableId,
}: {
  smallBlind?: number;
  bigBlind?: number;
  startingStack?: number;
  tableId: string;
}) {
  return destructureAxiosReq<void>(
    axios.patch(`/api/v1/table/${tableId}/`, {
      small_blind: smallBlind,
      big_blind: bigBlind,
      starting_stack: startingStack,
    })
  );
}

function getBaseUrl() {
  if (window.location.hostname.split('.')[0] === 'test') {
    return 'test.qtpkr.com';
  } else {
    return process.env.REACT_APP_BASE_URL;
  }
}

export function getTableConnection({
  userId,
  tableId,
}: {
  userId: string;
  tableId: string;
}) {
  return new WebSocket(
    `wss://${getBaseUrl()}/ws/v1?table=${tableId}&player=${userId}`
  );
}
