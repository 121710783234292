import React, { useContext } from 'react';

import { observer } from 'mobx-react-lite';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

import AccountView from '../../components/AccountView';
import { AuthContext } from '../../contexts/AuthContext';

const AccountContainer = observer(function AccountContainerComponent({
  handleUnauthorizedUser,
}: {
  handleUnauthorizedUser: () => void;
}) {
  const match = useRouteMatch<{ tableId: string }>();
  const authStore = useContext(AuthContext);

  if (!authStore?.email) {
    handleUnauthorizedUser();
  }

  function handleLogOut() {
    authStore?.logOut();
  }

  return (
    <Switch>
      <Route path={match.path}>
        <AccountView onLogOutClick={handleLogOut} />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
});

export default AccountContainer;
