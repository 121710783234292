import React, { useContext, useState } from 'react';

import { keyBy } from 'lodash';
import { observer } from 'mobx-react-lite';

import ModalSettings, { EModalContent } from '../../components/ModalSettings';
import { EModalComponent } from '../../components/SettingsFields';
import { TableContext } from '../../contexts/TableContext';
import { useSnackbars } from '../../services/useSnackbars';

export type EModalTypename = 'GAME_SETTINGS' | 'LOBBY' | 'BLINDS';

const MODALS: {
  typename: EModalTypename;
  settingsConfig: EModalComponent[];
  initialContent: EModalContent;
}[] = [
  {
    typename: 'GAME_SETTINGS',
    settingsConfig: ['BLINDS', 'STARTING_STACK'],
    initialContent: 'DEFAULT',
  },
  {
    typename: 'LOBBY',
    settingsConfig: ['BLINDS', 'STARTING_STACK'],
    initialContent: 'SETTINGS',
  },
  {
    typename: 'BLINDS',
    settingsConfig: ['BLINDS'],
    initialContent: 'SETTINGS',
  },
];

const MODAL_CONFIG = keyBy(MODALS, 'typename');

const ModalSettingsContainer = observer(
  ({
    isOpen = false,
    onClose = () => {},
    typename: typenameProp,
    onSettingsUpdateSuccess,
  }: {
    isOpen: boolean;
    onClose: () => void;
    typename?: EModalTypename;
    onSettingsUpdateSuccess: () => void;
  }) => {
    const typename = typenameProp || 'GAME_SETTINGS';
    const tableStore = useContext(TableContext)!;
    const adminId = tableStore.table!.admin_id;
    const userId = tableStore.table!.game.me.id;
    const setSnackbars = useSnackbars();
    const [isPending, setIsPending] = useState(false);

    function handleUpdateGameSettings(payload: {
      smallBlind: number;
      bigBlind: number;
      startingStack: number;
    }) {
      return new Promise<void>((resolve, reject) => {
        setIsPending(true);
        return tableStore
          .updateGameSettings(payload)
          .then(() => {
            onSettingsUpdateSuccess();
            setIsPending(false);
            setSnackbars([
              {
                id: 'SETTINGS_UPDATED',
                severity: 'success',
                message: 'Updated!',
                icon: '💅🏽',
              },
            ]);
            resolve();
          })
          .catch((error) => {
            setIsPending(false);
            setSnackbars([
              {
                id: 'SETTINGS_UPDATE_FAILED',
                severity: 'error',
                message: 'Update failed. Please try again.',
                icon: '😓',
              },
            ]);
            reject(error);
          });
      });
    }

    return (
      <ModalSettings
        isAdmin={adminId === userId}
        isOpen={isOpen}
        initialContent={MODAL_CONFIG[typename].initialContent}
        settingsConfig={MODAL_CONFIG[typename].settingsConfig}
        onClose={onClose}
        handleUpdateGameSettings={handleUpdateGameSettings}
        isPending={isPending}
      />
    );
  }
);

export default ModalSettingsContainer;
