import React, { memo } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import useWindowSize from '../../services/useWindowSize';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      transform: 'rotate(30deg)',
      transformOrigin: 'bottom right',
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: 110,
      right: -160,
      filter: 'saturate(0%)',
      opacity: 0.15,
      mixBlendMode: 'multiply',
    },
    pattern: {
      letterSpacing: 16,
      margin: 0,
      marginTop: 16,
      fontSize: 64,
      lineHeight: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      flexShrink: 0,
    },
  };
});

function Pattern(props) {
  return (
    <>
      <Box component="p" {...props}>
        <span role="img" aria-label="">
          🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠😷🦠
        </span>
      </Box>
    </>
  );
}

function PatternLine(props) {
  const classes = useStyles();
  return <Pattern className={classes.pattern} {...props} />;
}

function repeated(iterator, times) {
  return Array(times).fill(null).map(iterator);
}

function CommunityCardsBackground({ className }) {
  const classes = useStyles();
  const { width } = useWindowSize();
  return (
    <Box className={clsx(classes.root, className)}>
      {repeated(
        (_, i) => (
          <PatternLine
            key={i}
            style={{ transform: `translateX(${-i * 90}px)` }}
          />
        ),
        Math.floor(width ? 14 * (1 + width / 2000) : 14)
      )}
    </Box>
  );
}

export default memo(CommunityCardsBackground);
