import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import Footer from '../Footer';
import LayoutOnboarding from '../LayoutOnboarding';

const useStyles = makeStyles((theme) => {
  return {
    content: {
      marginBottom: 16,
    },
    heading: {
      fontWeight: 800,
      textAlign: 'left',
      lineHeight: 1.1,
      paddingBottom: 40,
      paddingTop: 0,
    },
    btn: {
      marginBottom: 8,
    },
  };
});

type LandingViewProps = {
  onStartClick: () => void;
  onSignUpClick: () => void;
  onLogInClick: () => void;
};

function LandingView({
  onStartClick,
  onSignUpClick,
  onLogInClick,
}: LandingViewProps) {
  const classes = useStyles();

  return (
    <LayoutOnboarding>
      <Box className={classes.content}>
        <Typography variant="h2" component="h1" className={classes.heading}>
          Let's play
          <br />
          poker!
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={onStartClick}
          fullWidth
          size="large"
          className={classes.btn}
          autoFocus={true}
        >
          Start a game
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          disableElevation
          onClick={onSignUpClick}
          fullWidth
          size="large"
          className={classes.btn}
        >
          Sign up
        </Button>
        <Button
          variant="text"
          color="secondary"
          disableElevation
          onClick={onLogInClick}
          fullWidth
          size="large"
          className={classes.btn}
        >
          Log in
        </Button>
      </Box>
      <Footer />
    </LayoutOnboarding>
  );
}

export default LandingView;
