import React, { useCallback, useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { Route, Redirect, Switch, useHistory } from 'react-router-dom';

import LandingView from '../../components/LandingView';
import LoginView from '../../components/LoginView';
import SetupView from '../../components/SetupView';
import SignUpView from '../../components/SignUpView';
import { colors } from '../../constants/theme';
import { RoutingContext } from '../../contexts/RoutingContext';
import { createPlayer, createTable } from '../../services/api';
import useSetRootCssProperty from '../../services/useSetRootCssProperty';
import { SnackbarsProvider, useSnackbars } from '../../services/useSnackbars';
import useWindowSize from '../../services/useWindowSize';
import AccountContainer from '../AccountContainer';
import { AuthContextProvider } from '../AuthContextProvider';
import TablesContainer from '../TablesContainer';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: 'calc(var(--vh, 1vh) * 100)',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: colors.light,
    },
  };
});

const TABLES_ROUTE = '/tables';

function Content() {
  const history = useHistory();

  const { height = 0 } = useWindowSize();
  const vh = height * 0.01;
  useSetRootCssProperty({
    '--vh': `${vh}px`,
  });

  const classes = useStyles();

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState();
  const [userId, setUserId] = useState('');
  const [tableId, setTableId] = useState('');

  const setSnackbars = useSnackbars();

  const routeToAccount = useCallback(() => {
    history.push('/account');
  }, [history]);
  const routeToLanding = useCallback(() => {
    history.push('/');
  }, [history]);
  const routeToLogIn = useCallback(() => {
    history.push('/login');
  }, [history]);
  const routeToSignUp = useCallback(() => {
    history.push('/sign-up');
  }, [history]);
  const routeToStartGame = useCallback(() => {
    history.push('/start');
  }, [history]);

  const routingContext = useMemo(
    () => ({
      routeToAccount,
      routeToLanding,
      routeToLogIn,
      routeToSignUp,
      routeToStartGame,
    }),
    [
      routeToAccount,
      routeToLanding,
      routeToLogIn,
      routeToSignUp,
      routeToStartGame,
    ]
  );

  function handleAddPlayerSuccess(tableId: string) {
    history.push(`${TABLES_ROUTE}/${tableId}`);
  }

  function handleSignUpSuccess() {
    routeToAccount();
    setSnackbars([
      {
        id: 'SIGN_UP',
        severity: 'success',
        message: `Sign up successful!`,
        icon: '🚀',
      },
    ]);
  }

  function handleLogInSuccess() {
    routeToAccount();
    setSnackbars([
      {
        id: 'LOG_IN',
        severity: 'success',
        message: `Welcome back!`,
        icon: '👋',
      },
    ]);
  }

  function handleStart({
    playerName,
    small_blind,
    big_blind,
    starting_stack,
  }: {
    playerName: string;
    small_blind: number;
    big_blind: number;
    starting_stack: number;
  }) {
    return new Promise<void>((resolve, reject) => {
      setIsPending(true);
      return createTable({ small_blind, big_blind, starting_stack })
        .then(({ table_id }) =>
          createPlayer({ tableId: table_id, name: playerName })
        )
        .then((table) => {
          handleAddPlayerSuccess(table.table_id);
          setUserId(table.game.me.id);
          setTableId(table.table_id);
          setIsPending(false);
          resolve();
        })
        .catch((error) => {
          setError(error);
          setIsPending(false);
          reject();
        });
    });
  }

  return (
    <RoutingContext.Provider value={routingContext}>
      <Box className={classes.root}>
        <Switch>
          <Route exact path="/">
            <LandingView
              onLogInClick={routeToLogIn}
              onSignUpClick={routeToSignUp}
              onStartClick={routeToStartGame}
            />
          </Route>
          <Route exact path="/start">
            <SetupView
              onSubmit={handleStart}
              isPending={isPending}
              error={error}
            />
          </Route>
          <Route exact path="/login">
            <LoginView onSuccess={handleLogInSuccess} />
          </Route>
          <Route exact path="/sign-up">
            <SignUpView onSuccess={handleSignUpSuccess} />
          </Route>
          <Route path={TABLES_ROUTE}>
            <TablesContainer userId={userId} tableId={tableId} />
          </Route>
          <Route exact path="/account">
            <AccountContainer handleUnauthorizedUser={routeToLogIn} />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Box>
    </RoutingContext.Provider>
  );
}

function App() {
  return (
    <AuthContextProvider>
      <SnackbarsProvider>
        <Content />
      </SnackbarsProvider>
    </AuthContextProvider>
  );
}

export default App;
