import React from 'react';
import ReactDOM from 'react-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { configure as configureMobX } from 'mobx';
import { BrowserRouter as Router } from 'react-router-dom';

import theme from './constants/theme';
import App from './containers/App';
import { configureLocalforage } from './services/configureLocalforage';
import * as serviceWorker from './serviceWorker';

configureLocalforage();

process.env.REACT_APP_ENV !== 'production' &&
  configureMobX({
    enforceActions: 'observed',
    computedRequiresReaction: true,
    reactionRequiresObservable: true,
    observableRequiresReaction: true,
    disableErrorBoundaries: true,
  });

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);

serviceWorker.unregister();
