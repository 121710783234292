import React from 'react';

import { observer } from 'mobx-react-lite';

import { AuthContext } from '../../contexts/AuthContext';
import AuthStore from '../../stores/AuthStore';

const authStore = new AuthStore();

export const AuthContextProvider = observer(
  ({ children }: { children: JSX.Element }) => {
    return (
      <AuthContext.Provider value={authStore}>{children}</AuthContext.Provider>
    );
  }
);
