import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

import { AuthContext } from '../../contexts/AuthContext';
import LayoutOnboarding from '../LayoutOnboarding';

const useStyles = makeStyles((theme) => {
  return {
    heading: {
      fontWeight: 800,
      textAlign: 'left',
      lineHeight: 1.1,
      paddingBottom: 40,
      paddingTop: 0,
    },
    form: {
      width: '100%',
    },
    nameInput: {
      marginBottom: 24,
    },
    caption: {
      paddingTop: 16,
    },
  };
});

type TFormValues = {
  email: string;
  password: string;
};

const LoginView = observer(function SignUpViewComponent({
  onSuccess,
}: {
  onSuccess: (email: string) => void;
}) {
  const classes = useStyles();

  const authStore = useContext(AuthContext);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .max(128, 'Needs to be less than 128 characters 😅')
      .required('Hold up...what’s your email?'),
    password: yup.string().required(),
  });

  function onSubmit({ email, password }: TFormValues) {
    return authStore?.logIn(email, password).then((resp) => {
      if (resp) {
        onSuccess(resp.email);
      }
    });
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
  } = useFormik<TFormValues>({
    validationSchema,
    onSubmit,
    initialValues: {
      email: '',
      password: '',
    },
  });

  return (
    <LayoutOnboarding>
      <Typography variant="h2" component="h1" className={classes.heading}>
        Log in
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit as (e: React.FormEvent<HTMLElement>) => void}
        className={classes.form}
      >
        <TextField
          className={classes.nameInput}
          label="Email"
          name="email"
          value={values.email}
          fullWidth
          inputProps={{
            maxLength: 128,
          }}
          disabled={authStore?.isLoading}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && !!errors.email}
          helperText={touched.email ? errors.email : ''}
          autoFocus={true}
          autoComplete="username"
        />
        <TextField
          className={classes.nameInput}
          label="Password"
          name="password"
          value={values.password}
          fullWidth
          inputProps={{
            maxLength: 48,
          }}
          disabled={authStore?.isLoading}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.password && !!errors.password}
          type="password"
          helperText={touched.password ? errors.password : ''}
          autoComplete="current-password"
        />

        <Button
          variant="contained"
          color="secondary"
          fullWidth
          type="submit"
          size="large"
          disabled={authStore?.isLoading}
          disableElevation
        >
          Next
        </Button>
        {!!authStore?.error && (
          <Typography
            color="error"
            variant="caption"
            component="p"
            className={classes.caption}
          >
            Yeaahhh...something is not working quite right, sorry.{' '}
            <span role="img" aria-label="sad face">
              😔
            </span>{' '}
            Maybe try again?
          </Typography>
        )}
      </Box>
    </LayoutOnboarding>
  );
});

export default LoginView;
