import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import ExitIcon from '@material-ui/icons/ExitToApp';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PersonIcon from '@material-ui/icons/Person';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Formik } from 'formik';
import isNumber from 'lodash/isNumber';
import { observer } from 'mobx-react-lite';
import useSound from 'use-sound';
import * as yup from 'yup';

import { colors } from '../../constants/theme';
import { AuthContext } from '../../contexts/AuthContext';
import { RoutingContext } from '../../contexts/RoutingContext';
import { TableContext } from '../../contexts/TableContext';
import { useSfx } from '../../services/useSfx';
import EditPlayersMenu from '../EditPlayersMenu';
import SettingsFields, {
  EModalComponent,
  TSettingsFormValues,
  validation,
} from '../SettingsFields';
import SuitColorsTab from '../SuitColorsTab/SuitColorsTab';

const yourTurn = require('../../assets/sfx/your_turn.mp3').default;

export type EModalContent = 'DEFAULT' | 'EDIT_PLAYERS' | 'SETTINGS';

const useStyles = makeStyles((theme) => {
  return {
    modal: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      outline: 0,
      width: 360,
      maxWidth: '90%',
      padding: 8,
      backgroundColor: colors.white,
      transition: 'background-color 0.3s ease',
      '&$editPlayers': {
        backgroundColor: colors.grayLight,
      },
    },
    editPlayers: {},
    accountBadge: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
    },
    volumeWrapper: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    volumeIconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    button: {
      marginBottom: 8,
    },
    exitButton: {
      background: colors.warning,
      '&:hover': {
        background: colors.warningHover,
      },
      marginBottom: 8,
    },
    dangerButton: {
      background: colors.danger,
      '&:hover': {
        background: colors.dangerHover,
      },
    },
    cancelSettingsBtn: {
      marginTop: 8,
    },
  };
});

const DefaultModalContent = observer(
  ({
    isAdmin = false,
    setContent = () => {},
    isPending = false,
    onClose = () => {},
    hasSettingsButton = false,
  }: {
    isAdmin?: boolean;
    setContent?: Dispatch<SetStateAction<EModalContent>>;
    isPending?: boolean;
    onClose?: () => void;
    hasSettingsButton?: boolean;
  }) => {
    const classes = useStyles();
    const authStore = useContext(AuthContext);
    const routingContext = useContext(RoutingContext);
    const [isConfirmingForfeit, setIsConfirmingForfeit] = useState(false);
    const { isMuted, setIsMuted, volume, setVolume } = useSfx();
    const tableStore = useContext(TableContext)!;
    const table = tableStore.table!;
    const userId = table.game.me.id;
    function handleForfeit() {
      return tableStore.removePlayer({ kicked: false, id: userId });
    }

    const [playSetVolume, { sound }] = useSound(yourTurn);

    const MuteButton = isMuted ? VolumeOffIcon : VolumeUpIcon;

    return (
      <>
        {authStore?.email && (
          <Button
            onClick={routingContext?.routeToAccount}
            variant="outlined"
            fullWidth
            className={classes.button}
            startIcon={<PersonIcon />}
          >
            {authStore.email}
          </Button>
        )}
        <Grid container spacing={2} className={classes.volumeWrapper}>
          <Grid item className={classes.volumeIconWrapper}>
            <MuteButton onClick={() => setIsMuted(!isMuted)} />
          </Grid>
          <Grid item xs>
            <Slider
              value={volume * 100}
              onChange={(_, val) => isNumber(val) && setVolume(val / 100)}
              onChangeCommitted={(_, val) => {
                isNumber(val) && sound.volume(val / 100);
                playSetVolume();
              }}
              aria-labelledby="continuous-slider"
            />
          </Grid>
        </Grid>
        {isAdmin && (
          <>
            {!!hasSettingsButton && (
              <Button
                onClick={() => setContent('SETTINGS')}
                variant="outlined"
                fullWidth
                className={classes.button}
              >
                Edit game settings
              </Button>
            )}
            <Button
              onClick={() => setContent('EDIT_PLAYERS')}
              variant="outlined"
              fullWidth
              className={classes.button}
            >
              Edit players
            </Button>
          </>
        )}
        <SuitColorsTab className={classes.button} />
        <Button
          onClick={() => tableStore.playerToSpectator(userId)}
          endIcon={<PauseCircleOutlineIcon />}
          disabled={isPending}
          className={classes.button}
          variant="outlined"
          fullWidth
        >
          Stand up
        </Button>
        <Button
          className={clsx(classes.exitButton, {
            [classes.dangerButton]: isConfirmingForfeit,
          })}
          onClick={() =>
            isConfirmingForfeit ? handleForfeit() : setIsConfirmingForfeit(true)
          }
          endIcon={<ExitIcon />}
          disabled={isPending}
          variant="contained"
          fullWidth
        >
          {isConfirmingForfeit ? 'Confirm forfeit' : 'Forfeit'}
        </Button>
        <Button onClick={onClose} fullWidth>
          Close
        </Button>
      </>
    );
  }
);

const ModalSettings = observer(
  ({
    handleUpdateGameSettings,
    initialContent,
    isAdmin,
    isOpen,
    isPending,
    onClose,
    settingsConfig,
  }: {
    handleUpdateGameSettings: (payload: {
      smallBlind: number;
      bigBlind: number;
      startingStack: number;
    }) => void;
    initialContent: EModalContent;
    isAdmin: boolean;
    isOpen: boolean;
    isPending: boolean;
    onClose: () => void;
    settingsConfig: EModalComponent[];
  }) => {
    const tableStore = useContext(TableContext)!;
    const table = tableStore.table!;
    const [content, setContent] = useState(initialContent);

    useEffect(
      function setInitialContent() {
        if (isOpen) {
          setContent(initialContent || 'DEFAULT');
        }
      },
      [initialContent, isOpen]
    );

    const classes = useStyles();

    const schema = settingsConfig.reduce(
      (
        acc: {
          name: string;
          label: string;
          value: string | boolean | number;
          yup: yup.SchemaOf<string | boolean | number>;
        }[],
        component: EModalComponent
      ) => {
        switch (component) {
          case 'BLINDS':
            return [
              ...acc,
              {
                name: 'big_blind',
                label: 'Big blind',
                value: table.settings.big_blind,
                yup: validation.big_blind,
              },
              {
                name: 'small_blind',
                label: 'Small blind',
                value: table.settings.small_blind,
                yup: validation.small_blind,
              },
            ];
          case 'STARTING_STACK':
            return [
              ...acc,
              {
                name: 'starting_stack',
                label: 'Starting stack',
                value: table.settings.starting_stack,
                yup: validation.starting_stack,
              },
            ];
          default:
            return acc;
        }
      },
      []
    );

    const validationSchema = yup.object(
      Object.assign(
        {},
        ...schema.map(({ name, yup }) => ({
          [name]: yup,
        }))
      )
    );

    const initialValues = Object.assign(
      {},
      ...schema.map(({ name, value }) => ({
        [name]: value,
      }))
    );

    function handleSubmit(values: TSettingsFormValues) {
      const castValues: TSettingsFormValues | undefined = validationSchema.cast(
        values
      );
      if (castValues) {
        return handleUpdateGameSettings({
          bigBlind: castValues.big_blind!,
          smallBlind: castValues.small_blind!,
          startingStack: castValues.starting_stack!,
        });
      }
    }

    return (
      <Modal
        aria-labelledby="Options"
        aria-describedby="Game options"
        className={classes.modal}
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Paper
            className={clsx(classes.paper, {
              [classes.editPlayers]: content === 'EDIT_PLAYERS',
            })}
            elevation={6}
          >
            {content === 'DEFAULT' && (
              <DefaultModalContent
                hasSettingsButton={!!schema.length}
                {...{
                  onClose,
                  isAdmin,
                  setContent,
                  isPending,
                }}
              />
            )}
            {content === 'SETTINGS' && (
              <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;

                  return (
                    <form onSubmit={handleSubmit}>
                      <SettingsFields
                        settingsConfig={settingsConfig}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isDisabled={isPending}
                        errors={errors}
                        touched={touched}
                        values={values}
                      />
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        type="submit"
                        size="large"
                        disabled={isPending}
                      >
                        Save
                      </Button>
                      <Button
                        className={classes.cancelSettingsBtn}
                        onClick={
                          initialContent !== 'DEFAULT'
                            ? onClose
                            : () => setContent('DEFAULT')
                        }
                        fullWidth
                      >
                        Cancel
                      </Button>
                    </form>
                  );
                }}
              </Formik>
            )}
            {content === 'EDIT_PLAYERS' && (
              <EditPlayersMenu
                isPending={isPending}
                onClose={() => setContent('DEFAULT')}
                buttonText={initialContent === content ? 'Close' : 'Back'}
              />
            )}
          </Paper>
        </Fade>
      </Modal>
    );
  }
);

export default ModalSettings;
