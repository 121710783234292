import { createContext } from 'react';

export const RoutingContext = createContext<
  | {
      routeToLogIn: () => void;
      routeToSignUp: () => void;
      routeToAccount: () => void;
      routeToLanding: () => void;
      routeToStartGame: () => void;
    }
  | undefined
>(undefined);
