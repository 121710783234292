import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { colors } from '../../constants/theme';
import { AuthContext } from '../../contexts/AuthContext';
import Footer from '../Footer';
import KeyValDisplay from '../KeyValDisplay/KeyValDisplay';
import LayoutOnboarding from '../LayoutOnboarding';

const useStyles = makeStyles((theme) => {
  return {
    content: {
      marginBottom: 16,
    },
    heading: {
      fontWeight: 800,
      textAlign: 'left',
      lineHeight: 1.1,
      paddingBottom: 40,
      paddingTop: 0,
    },
    keyValDisplay: {
      marginBottom: 8,
      textAlign: 'left',
    },
    btn: {
      marginBottom: 8,
    },
    confirmDangerButton: {
      border: `1px solid ${colors.warning}`,
      color: colors.warning,
      // transition: 'color 0.2s ease',
      '&:hover': {
        border: `1px solid ${colors.warningHover}`,
        // color: colors.warningHover,
      },
    },
  };
});

type LandingViewProps = {
  onLogOutClick: () => void;
};

const AccountView = observer(function AccountViewComponent({
  onLogOutClick,
}: LandingViewProps) {
  const classes = useStyles();
  const authStore = useContext(AuthContext);

  return (
    <LayoutOnboarding>
      <Box className={classes.content}>
        <Typography variant="h2" component="h1" className={classes.heading}>
          Account settings
        </Typography>
        <KeyValDisplay
          className={classes.keyValDisplay}
          label="Email"
          value={authStore?.email}
          variant="outlined"
        />
        <Button
          variant="outlined"
          color="secondary"
          disableElevation
          onClick={onLogOutClick}
          fullWidth
          size="large"
          className={clsx(classes.btn, classes.confirmDangerButton)}
        >
          Log out
        </Button>
      </Box>
      <Footer />
    </LayoutOnboarding>
  );
});

export default AccountView;
